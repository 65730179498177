import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  showModal: false,
  modalDetails: {},
};

const rewatchModalSlice = createSlice({
  name: "rewatch",
  initialState,
  reducers: {
    setShowModal: (state, { payload }) => {
      state.showModal = payload;
    },
    setModalDetails: (state, { payload }) => {
      state.modalDetails = payload;
    },
  },
});

export const { setShowModal, setModalDetails } = rewatchModalSlice.actions;
export default rewatchModalSlice.reducer;
