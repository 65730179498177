import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Mixpanel } from "../../utils/Mixpanel";
import _ from "lodash";

import { PageHeader, Loading, Card } from "../../components/";

const Content = () => {
  const [loading, setLoading] = useState(true);
  const [modalities, setModalities] = useState([]);
  const [content, setContent] = useState([]);

  const [userModalityId, setUserModalityId] = useState(0);

  const handModalityChange = (e) => {
    loadModalityContent(e.target.value);
  };

  const loadModalities = async () => {
    try {
      const apiURL = `${process.env.REACT_APP_API_URL}/modalities/`;
      const res = await axios({ method: "GET", url: apiURL });
      if (res.status !== 200) {
        throw Error("Failed to grab Modalities");
      }
      const modalitiesList = _.sortBy(
        res.data.map((modality) => {
          return {
            display_label: modality.display_label,
            modality_id: modality.id,
            body_content: modality.body_content,
          };
        }),
        "display_label"
      );
      setModalities(modalitiesList);
    } catch (error) {
      console.log(error);
    }
  };

  const loadModalityContent = async (modalityId) => {
    try {
      await setLoading(true);
      const apiURL = `${process.env.REACT_APP_API_URL}/modalities/${modalityId}/`;
      const res = await axios({ method: "GET", url: apiURL });
      if (res.status !== 200) {
        throw Error("Failed to grab Modalities");
      }
      setContent(res.data);

      //now transform
      // const onlyWebinars = res.data.filter((vod) => {
      //   const vodModalities = vod.modalities.map((modality) => {
      //     const streamEntries = modality.stream_entry.filter((stream) => {
      //       stream.webinar.map((webinar) => Object.assign(stream, webinar));
      //       delete stream.article;
      //       delete stream.video;
      //       delete stream.webinar;

      //       return stream.webinar_key;
      //     });

      //     modality.stream_entry = _.sortBy(streamEntries, "start_time");
      //     return modality;
      //   });
      //   vod.modalities = vodModalities;
      //   return vod.is_vod === false;
      // });

      const formattedcontent = res.data.stream_entry.filter((stream) => {
        if (stream.article.length) {
          stream.article.map((article) => Object.assign(stream, article));
          stream.content_type = "article";
        } else if (stream.video.length) {
          stream.video.map((video) => Object.assign(stream, video));
          stream.content_type = "video";
        } else {
          //remove it
        }

        delete stream.video;
        delete stream.article;

        return !stream.webinar.length;
      });

      setTimeout(() => {
        setContent(formattedcontent);
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setUserModalityId(localStorage.getItem("modality"));

    loadModalities();
    loadModalityContent(localStorage.getItem("modality"));
    Mixpanel.track("View | Content | Existing");
  }, []);

  return (
    <>
      <PageHeader
        image={"https://virtualopenday.endeavour.edu.au/media/NHCM-Content.jpg"}
        title={"Content"}
        description={"Explore our natural health articles and videos."}
      />
      <section
        id="filters"
        className="flex flex-col justify-center items-center w-full md:w-1/2 mb-8 m-auto"
      >
        {modalities.length ? (
          <>
            <p class="mb-2">Select the subject you're interested in</p>
            <form
              className="mb-1 bg-white p-8 pt-1 pb-0 rounded"
              id="select-modality-content-form"
            >
              <div className="">
                <select
                  name="closest_campus"
                  className="text-box"
                  label="Select campus"
                  onChange={handModalityChange}
                  defaultValue={userModalityId}
                >
                  {" "}
                  {modalities.map((modality, index) => (
                    <option value={modality.modality_id} key={index}>
                      {modality.display_label}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          </>
        ) : null}
      </section>
      <section className="w-full p-2 md:px-32 lg:px-64 xl:px-64 text-center border-r">
        <div className="flex items-center justify-center flex-wrap -mx-4">
          {loading ? (
            <Loading loading={loading} />
          ) : content.length ? (
            content.map((content, index) =>
              content.feature_image && content.snippet_content ? (
                <Card details={content} type="content" key={index} />
              ) : null
            )
          ) : (
            <div className="w-full lg:w-1/2 m-4 p-4 bg-offwhite rounded">
              <p className="font-semibold text-lg mb-2">Coming soon!</p>
              <p className="font-small">
                Browse our webinars and register for any any you are interested
                in.
              </p>
              <Link to="/home" role="button" className="btn-joy mt-4 mb-0">
                Browse Webinars
              </Link>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Content;
