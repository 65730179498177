import React, { useEffect } from "react";

const SproutVideoCard = ({ details }) => {
  useEffect(() => {
    console.log("init vid");
  }, [details]);
  return (
    <div
      className="content-card flex flex-wrap items-center w-full h-full mb-2  rounded"
      data-contenttype={details.content_type}
    >
      <div className="w-full px-2">
        <div className="p-4 text-left">
          <h3 className="text-xl my-3 font-heading font-semibold mt-4 ">
            {details.title}
          </h3>
          <p
            className="text-sm font-normal w-full h-full mb-4"
            dangerouslySetInnerHTML={{ __html: details.snippet_content }}
          ></p>
          <div
            className="w-full h-auto"
            dangerouslySetInnerHTML={{ __html: details.webinar_rewatch_embed }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default SproutVideoCard;
