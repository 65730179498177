import React from "react";

const PageHeader = ({ image, title, description }) => {
  return (
    <header
      className="flex flex-wrap items-center content-center w-full h-auto min:h-fifty mx-auto px-16 md:px-32 xl:px-64 py-20 mb-16 text-center bg-center"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${image})`,
      }}
    >
      <div className="w-full lg:px-32 xl:px-32">
        <h1 className="text-offwhite mt-2 mb-6 leading-tight font-heading font-normal">
          {title}
        </h1>
        <h3 className="mb-8 text-offwhite leading-relaxed font-semibold">
          {description}
        </h3>
      </div>
    </header>
  );
};

export default PageHeader;
