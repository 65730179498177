import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import rewatchModalSlice from "../slices/rewatchModalSlice";
import userSlice from "../slices/userSlice";

const reducer = combineReducers({
  rewatchModalSlice,
  userSlice,
});

const store = configureStore({
  reducer,
});

export default store;
