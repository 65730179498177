import React from "react";
import Transition from "./Transition.js";
import SproutVideoCard from "../Card/SproutVideoCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";

import { useDispatch, useSelector } from "react-redux";
import { setShowModal, setModalDetails } from "../../slices/rewatchModalSlice";

const SproutVideoModal = () => {
  const { modalDetails } = useSelector((state) => state).rewatchModalSlice;
  const dispatch = useDispatch();

  const handleToggle = () => {
    dispatch(setShowModal(false));
    dispatch(setModalDetails({}));
  };

  return (
    <Transition
      show={true}
      enter="transition ease-out duration-100 transform"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="transition ease-in duration-75 transform"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div className="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <div
          className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:w-full md:w-2/3"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <SproutVideoCard details={modalDetails} />
            </div>
          </div>
          <div className="bg-gray-50 absolute top-0 right-0 p-4">
            <button
              type="button"
              className="inline-block w-auto leading-none text-2xl bg-white hover:bg-white hover:border-wisdom hover:text-wisdom rounded"
              onClick={() => handleToggle()}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </button>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default SproutVideoModal;
