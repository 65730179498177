import React from "react";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

import { Mixpanel } from "../../utils/Mixpanel";

const Landing = () => {
  let history = useHistory();

  const [isVod] = useState(JSON.parse(process.env.REACT_APP_IS_VOD));

  useEffect(() => {
    if (
      localStorage.getItem("modality") &&
      localStorage.getItem("token") &&
      process.env.REACT_APP_WEBINARS_OPEN &&
      process.env.REACT_APP_WEBINARS_OPEN === "true"
    ) {
      Mixpanel.track("View | Landing Page | Existing");
      history.push("/home");
      return;
    }
    Mixpanel.track("View | Landing Page | New");
  }, [history]);

  return (
    <div
      className="px-auto py-auto w-full h-full min-h-screen bg-earth bg-cover bg-center"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)), url(${process.env.REACT_APP_META_IMAGE})`,
      }}
    >
      <section className="w-full h-screen flex px-4">
        <div className="m-auto">
          <img
            src="https://oday.endeavour.edu.au/media/endeavour_digital_left_white_yw9Tv5U.png"
            alt=""
            className="h-16 w-auto m-auto"
          />
          <div className="text-center">
            <h1 className="mt-4 mb-6 leading-tight font-heading text-white">
              {isVod ? "Virtual Open Day" : "Future Of Wellness"}
            </h1>
            <p className="text-lg font-semibold text-white leading-relaxed mb-8">
              Turn your passion into your purpose with a
              career&nbsp;in&nbsp;natural&nbsp;health.
            </p>
          </div>

          {process.env.REACT_APP_IS_LIVE === "true" ? (
            <div className="text-center">
              <button
                type="submit"
                className="inline-block btn-joy mx-w-full py-4 px-8 shadow mt-4"
                onClick={() => history.push("/register")}
              >
                Register
              </button>

              {process.env.REACT_APP_WEBINARS_OPEN &&
              process.env.REACT_APP_WEBINARS_OPEN === "true" ? (
                <button
                  type="submit"
                  className="inline-block mx-w-full py-4 px-8 leading-none text-white rounded mt-4"
                  onClick={() => history.push("/login")}
                >
                  Login
                </button>
              ) : null}
            </div>
          ) : (
            <div className="text-center">
              <p className="text-lg font-semibold text-white">Coming Soon</p>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Landing;
