import axios from "axios";
import _ from "lodash";
import store from "../store/index";
import { setUserDetails } from "../slices/userSlice";

const getRegistrant = async (registrantKey) => {
  try {
    const userToken = localStorage.getItem("token");

    const apiUrl = `${process.env.REACT_APP_API_URL}/registrant/${userToken}/`;
    const res = await axios.get(apiUrl);
    if (res.status !== 200) {
      throw Error("No user found");
    }

    await store.dispatch(setUserDetails(res.data.details.registrant));

    let sortedWebinars = res.data.details.webinars.map((webinar) => {
      Object.assign(webinar, webinar.webinar[0]);
      delete webinar.webinar;
      return webinar;
    });

    sortedWebinars = await _.sortBy(sortedWebinars, "start_time");
    return sortedWebinars;
  } catch (error) {
    return { error: true, message: error };
  }
};

export { getRegistrant };
