import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

import { Mixpanel } from "../../utils/Mixpanel";

const Login = () => {
  let history = useHistory();
  const { register, handleSubmit } = useForm();

  const [isValidating, setIsValidating] = useState(false);
  const [validationErrors, setValidationErrors] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("modality") && localStorage.getItem("token")) {
      Mixpanel.track("View | Login | Existing");
      history.push("/home");
      return;
    }
    Mixpanel.track("View | Login | New");
  }, [history]);

  const validateUser = async (data) => {
    setIsValidating(true);
    try {
      const payload = {
        email_address: data.email_address.toLowerCase(),
      };

      const apiUrl = `${process.env.REACT_APP_API_URL}/validate-user/`;

      const res = await axios({
        method: "POST",
        url: apiUrl,
        data: payload,
        headers: { "Content-Type": "application/json" },
      });

      if (res.status !== (await 200)) {
        setValidationErrors(true);
        throw new Error("We dont know those details");
      }

      const urlToken = res.data.details.url_token;
      const modalityId = res.data.details.modality_id;
      const campusId = res.data.details.closest_campus_id;
      const campus = res.data.details.closest_campus;

      Mixpanel.identify(urlToken);
      Mixpanel.people.set({
        uid: urlToken,
        $email: data.email_address,
        modalityId: modalityId,
        campusId: campusId,
        campus: campus,
      });

      setTimeout(async () => {
        await localStorage.setItem("token", urlToken);
        await localStorage.setItem("modality", modalityId);
        await localStorage.setItem("campusId", campusId);
        await localStorage.setItem("campus", campus);

        Mixpanel.track("Login | Success");
        history.push("/home");
        setIsValidating(false);
      }, 1000);
    } catch (error) {
      setIsValidating(false);
      setValidationErrors(true);
      Mixpanel.track("Login | Error");
    }
  };

  return (
    <div
      className="px-auto py-auto w-full h-full min-h-screen bg-earth bg-cover bg-center"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)), url(${process.env.REACT_APP_META_IMAGE})`,
      }}
    >
      <section className="w-full h-screen flex">
        <div className="m-auto w-full max-w-md">
          <form
            onSubmit={handleSubmit(validateUser)}
            className="mb-4 bg-white p-8 rounded"
          >
            <div className="mb-4 text-center">
              <h1 className="">Login</h1>
              {validationErrors ? (
                <div
                  className="flex items-center bg-joy text-white text-sm font-semibold px-4 py-3 w-full mb-8 mt-8 rounded"
                  role="alert"
                >
                  {/* <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="https://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                  </svg> */}
                  <p className="text-left">
                    Nothing found for that email address. <br />
                    <br />
                    Please{" "}
                    <Link to="/register" className="underline">
                      register
                    </Link>{" "}
                    or try again.
                    <br />
                    <br />
                    If you're having issues please contact
                    events@endeavour.edu.au
                  </p>
                </div>
              ) : null}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm mb-2">Email</label>
              <input
                name="email_address"
                type="email"
                ref={register({ required: true })}
                className="text-box"
                placeholder="youremail@email.com"
                autoCapitalize={"none"}
              />
            </div>

            <div className="mb-5 flex justify-center w-full">
              <button type="submit" className="btn-joy">
                {isValidating ? (
                  <ClipLoader size={24} color={"#fff"} />
                ) : (
                  "Login"
                )}
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Login;
