import React, { useEffect, useState } from "react";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import "react-multi-carousel/lib/styles.css";
import _ from "lodash";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";

import { WebinarCard, PageHeader, WebinarsCarousel } from "../../components/";
import SproutVideoModal from "../../components/VideoModal/SproutVideoModal";
import { getRegistrant } from "../../api";

const WebinarsWeekly = () => {
  let history = useHistory();
  const { showModal, modalDetails } = useSelector(
    (state) => state
  ).rewatchModalSlice;

  const [vods, setVods] = useState([]);
  //decides if to show Grid or Swipe
  const [isVod] = useState(JSON.parse(process.env.REACT_APP_IS_VOD));
  const [isLoading, setIsLoading] = useState(true);
  const [registrantWebinars, setRegistrantWebinars] = useState([]);

  useEffect(() => {
    const loadWebinars = async () => {
      try {
        const regWebinarsArray = await getRegistrant();

        if (regWebinarsArray.error) {
          throw Error(registrantWebinars.message);
        }

        setRegistrantWebinars(
          regWebinarsArray.map((webinar) => {
            return webinar.id;
          })
        );

        const apiUrl = `${process.env.REACT_APP_API_URL}/webinars/`;

        const res = await axios({
          method: "GET",
          url: apiUrl,
        });

        if (res.status !== (await 200)) {
          throw Error("Couldnt grab webinars :(");
        }

        const onlyWebinars = res.data.details.map((vod) => {
          vod.webinars = _.sortBy(vod.webinars, "start_time");
          return vod;
        });

        setVods(
          onlyWebinars.filter((vod) => {
            return vod.is_vod === isVod;
          })
        );
        setIsLoading(false);
      } catch (error) {
        await localStorage.clear();

        history.go(0);
      }
    };
    loadWebinars();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (vods.length) {
    } else {
      setIsLoading(true);
    }
  }, [vods]);

  return (
    <>
      <PageHeader
        image={
          "https://virtualopenday.endeavour.edu.au/media/NHCM-Webinars.jpg"
        }
        title={"Webinars and Info Sessions"}
        description={
          "Join us at our interactive webinars and informative sessions. Tailor your experience by registering for the events that suit you."
        }
      />

      <section className="h-auto min-h-screen mt-8 pl-4 pr-4">
        {isLoading ? (
          <div className="w-full h-full text-center">
            <ClipLoader size={48} color={"#000000"} />
          </div>
        ) : (
          vods.map((vod, index) =>
            isVod ? (
              <div key={index} className="w-full m-auto">
                <div className="mx-4">
                  <h2 className="font-semibold text-lg">{vod.display_label}</h2>
                  <p className="text-normal"></p>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 m-auto gap-3 py-4 px-4">
                  {vod.webinars.map((webinar, index) =>
                    webinar.start_time &&
                    webinar.end_time &&
                    webinar.webinar_key ? (
                      <WebinarCard
                        webinar={webinar}
                        key={webinar.id}
                        registered={registrantWebinars.includes(webinar.id)}
                      />
                    ) : null
                  )}
                </div>
              </div>
            ) : (
              <div key={index} className="m-4 w-full p-4 shadow-md mb-8">
                <div className="mx-4">
                  <h2 className="font-semibold text-lg">{vod.display_label}</h2>
                  <p className="text-normal">
                    {moment(vod.start_date).format("MMM D")} -{" "}
                    {moment(vod.end_date).format("D")}
                  </p>
                </div>
                <div className="w-auto">
                  <WebinarsCarousel
                    webinars={vod.webinars}
                    registrantWebinars={registrantWebinars}
                    startDate={vod.start_date}
                    endDate={vod.end_date}
                  />
                </div>
              </div>
            )
          )
        )}
      </section>
      {showModal && modalDetails && modalDetails.id ? (
        <SproutVideoModal display={showModal} details={modalDetails} />
      ) : null}
    </>
  );
};

export default WebinarsWeekly;
