import React, { useEffect, useState } from "react";

const VideoCard = ({ details }) => {
  const [embedCode, setEmbedCode] = useState("");
  useEffect(() => {
    const createEmbed = async () => {
      let videoSplit;
      let videoId;
      let embedCode;

      if (details.watch_video_link.includes("https://youtu.be")) {
        videoSplit = details.watch_video_link.split(".be/");
        videoId = videoSplit[1];
      } else {
        videoSplit = details.watch_video_link.split("?v=");
        videoId = videoSplit[1];
      }

      embedCode = `<style>.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }</style><div class='embed-container'><iframe src='https://www.youtube.com/embed/${videoId}?autoplay=1' frameborder='0' allowfullscreen></iframe></div>`;
      setEmbedCode(embedCode);
    };
    createEmbed();
  }, [details]);

  return (
    <div
      className="content-card flex flex-wrap items-center w-full h-full mb-2  rounded"
      data-contenttype={details.content_type}
    >
      <div className="w-full px-2">
        <div className="p-4 text-left">
          <h3 className="text-xl my-3 font-heading font-semibold mt-4 ">
            {details.title}
          </h3>
          <p
            className="text-sm font-normal w-full h-full mb-4"
            dangerouslySetInnerHTML={{ __html: details.snippet_content }}
          ></p>
          <div
            className="w-full h-auto"
            dangerouslySetInnerHTML={{ __html: embedCode }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
