import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Link,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { WebinarsWeekly, Content, MySessions } from "../../views";

import VideoModal from "../../components/VideoModal/VideoModal";

import { Mixpanel } from "../../utils/Mixpanel";
import axios from "axios";
import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import { useSelector } from "react-redux";

const Home = () => {
  let history = useHistory();
  const { userDetails } = useSelector((state) => state).userSlice;

  const [showModal, setShowModal] = useState(false);
  const [showCampusTour, setShowCampusTour] = useState(false);
  const [campusDetails, setCampusDetails] = useState({});

  useEffect(() => {
    if (!localStorage.getItem("wt")) {
      setShowModal(true);
      localStorage.setItem("wt", "1");
    }
  }, []);

  const StepContainer = ({ title, body, lastStep = false }) => {
    return (
      <div className="p-4 md:p-16 flex items-center flex-col">
        <p className="font-semibold text-2xl mb-4 px-8">{title}</p>
        <p className="">{body}</p>
        {lastStep ? (
          <button onClick={() => toggleModal()} className="btn-joy mt-4 mb-0 ">
            Get Started
          </button>
        ) : null}
      </div>
    );
  };

  const stepsStyle = {
    height: "auto",
    width: "90%",
    maxWidth: "480px",
    // backgroundColor: "rgb(198, 170, 118)",
  };

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  const steps = [
    {
      content: () => (
        <StepContainer
          title={`Welcome to ${process.env.REACT_APP_REGISTRATION_PAGE_NAME}${
            userDetails.first_name ? ", " + userDetails.first_name + "!" : "!"
          }`}
          body="Here's a quick run through of what you can do."
        />
      ),
      style: stepsStyle,
    },
    {
      selector: "#webinars",
      content: () => (
        <StepContainer
          title="Register for a webinar"
          body="Join one of our interactive webinars and information sessions, presented by industry experts."
        />
      ),
      style: stepsStyle,
    },
    {
      selector: "#campus-tour",
      content: () => (
        <StepContainer
          title="View A Campus Tour"
          body="Watch a video and learn more about the closest campus to you."
        />
      ),
      style: stepsStyle,
    },
    {
      selector: "#view-content",
      content: () => (
        <StepContainer
          title="Read Curated Content"
          body="Explore our natural health articles and videos relating to the field you're interested in."
        />
      ),
      style: stepsStyle,
    },
    {
      selector: "#my-sessions",
      content: () => (
        <StepContainer
          title="View My Sessions"
          body="See a list of your upcoming webinars."
        />
      ),
      style: stepsStyle,
    },
    {
      selector: "",
      content: () => (
        <StepContainer
          title="That's it!"
          body="Let's do this"
          lastStep={true}
        />
      ),
      style: stepsStyle,
    },
  ];

  const getCampusDetails = async () => {
    if (localStorage.getItem("campusId")) {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/campuses/${localStorage.getItem(
          "campusId"
        )}`
      );

      if (!res.data.id) {
        return;
      }
      const details = {
        watch_video_link: res.data.video_url,
        title: res.data.title,
        content_type: "video",
        snippet_content: res.data.description,
      };
      setCampusDetails(details);
    }
  };

  const logout = async () => {
    await setShowModal(false);
    await localStorage.clear();
    Mixpanel.track("Logout");
    history.push("/");
  };

  const toggleModal = () => {
    setShowModal(!showModal);
    return showModal;
  };

  const toggleCampusTourModal = () => {
    Mixpanel.track("Nav | Campus Tour");
    setShowCampusTour(!showCampusTour);
    return showCampusTour;
  };

  useEffect(() => {
    getCampusDetails();
    Mixpanel.track("View | Home | Existing");
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mx-auto max-w-full border-t-8 border-wisdom">
      <Router>
        <nav className="flex items-center justify-center flex-wrap bg-chia p-2 w-full">
          <div className="hidden md:flex items-center flex-shrink-0 text-white ml-2 absolute left-0">
            <Link to="/home">
              <img
                src="https://virtualopenday.endeavour.edu.au/media/endeavour_symbol_white.png"
                className="h-12"
                alt="Endeavour College of Natural Health"
              />
            </Link>
          </div>

          <div className="text-center flex flex-row justify-center items-center w-full md:w-auto">
            <Link to="/home" role="button" className="btn-nav" id="webinars">
              Webinars
            </Link>

            {campusDetails ? (
              <button
                id="campus-tour"
                className="btn-nav"
                onClick={() => toggleCampusTourModal()}
              >
                Campus Tour
              </button>
            ) : null}

            <Link
              id="view-content"
              to="/content"
              role="button"
              className="btn-nav"
            >
              Content
            </Link>

            <Link
              id="my-sessions"
              to="/my-sessions"
              role="button"
              className="btn-nav"
            >
              My Sessions
            </Link>

            {/* <Link
              onClick={() => logout()}
              to=""
              role="button"
              className=""
            >
              Logout
            </Link> */}
          </div>
        </nav>

        <Switch>
          <Route exact path="/content">
            <Content />
          </Route>
          <Route path="/home">
            <WebinarsWeekly />
          </Route>
          <Route path="/my-sessions">
            <MySessions />
          </Route>
        </Switch>
      </Router>
      <footer className="p-4 bg-chia mt-32">
        <div className="flex flex-col w-full md:w-3/4 p-8">
          <div className="mb-4">
            <a
              href="https://www.endeavour.edu.au"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src="https://oday.endeavour.edu.au/media/endeavour_digital_left_white_yw9Tv5U.png"
                alt=""
                className="h-16 w-auto"
              />
            </a>
          </div>
          <div className="text-offwhite text-sm">
            <p className="mb-2">
              Endeavour College of Natural Health is the largest private Higher
              Education provider of natural medicine courses in the Southern
              Hemisphere. We support our students with lecturers from the
              forefront of the complementary medicine industry, cutting edge
              curricula, and Australia’s largest choice of
              natural&nbsp;health&nbsp;degrees.
            </p>
            <p className="mb-2">
              Endeavour College of Natural Health acknowledges the Australian
              Aboriginal and Torres Strait Islander peoples as the first
              inhabitants of the nation and the Traditional Custodians of the
              lands where we live, learn&nbsp;and&nbsp;work.
            </p>
            <p className="mb-2">
              © Australian College of Natural Medicine Pty Ltd T/A Endeavour
              College of Natural Health.RTO: 31489 | CRICOS: 00231G - NAT | HEP:
              PRV12070
            </p>
            <Link
              onClick={() => logout()}
              to=""
              role="button"
              className="btn-trans"
            >
              Logout
            </Link>
          </div>
        </div>
      </footer>
      {/* <OnboardingModal display={showModal} toggleModal={toggleModal} /> */}
      {campusDetails ? (
        <VideoModal
          display={showCampusTour}
          toggleModal={toggleCampusTourModal}
          details={campusDetails}
        />
      ) : null}
      <Tour
        style={{ backgroundColor: "red" }}
        steps={steps}
        isOpen={showModal}
        onRequestClose={() => toggleModal()}
        showNumber={false}
        showNavigation={true}
        rounded={5}
        highlightedMaskClassName="border border-joy"
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        disableInteraction //for styling of highlight overlay
        // lastStepNextButton={
        //   <button className="btn mt-4 mb-0 bg-joy text-center">
        //     Get Started
        //   </button>
        // }
      />
    </div>
  );
};

export default Home;
