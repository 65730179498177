import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  userDetails: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetails: (state, { payload }) => {
      state.userDetails = payload;
    },
  },
});

export const { setUserDetails } = userSlice.actions;
export default userSlice.reducer;
