import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import _ from "lodash";

import { Mixpanel } from "../../utils/Mixpanel";

const Register = () => {
  let history = useHistory();
  const { register, handleSubmit } = useForm();

  const [isRegistering, setIsRegistering] = useState(false);
  const [registrationErrors, setRegistrationErrors] = useState();
  const [campuses, setCampuses] = useState([]);
  const [modalities, setModalities] = useState([]);
  const [success, setSuccess] = useState(false);

  const registerUser = async (data) => {
    setIsRegistering(true);
    try {
      const [campus] = campuses.filter((thisCampus) => {
        return thisCampus.id === Number(data.closest_campus);
      });

      const payload = {
        first_name: data.first_name,
        last_name: data.last_name,
        email_address: data.email_address.toLowerCase(),
        phone_number: data.phone_number,
        closest_campus: campus.title,
        campus: campus.id,
        stream: data.stream,
        how_did_you_hear_about_this_event:
          data.how_did_you_hear_about_this_event,
        hubspot_form_id: process.env.REACT_APP_HUBSPOT_FORM_ID,
        post_code: data.post_code,
        student_type: data.student_type,
        registration_page_url: process.env.REACT_APP_REGISTRATION_PAGE_URL,
        registration_page_name: process.env.REACT_APP_REGISTRATION_PAGE_NAME,
      };

      const apiUrl = `${process.env.REACT_APP_API_URL}/register-user/`;

      const res = await axios({
        method: "POST",
        url: apiUrl,
        data: payload,
        headers: { "Content-Type": "application/json" },
      });

      if (res.status !== (await 201)) {
        throw Error("Couldnt register you");
      }

      const urlToken = res.data.details[0].url_token;
      const modality = res.data.details[0].modality;
      const closestCampus = res.data.details[0].closest_campus;
      const closestCampusId = res.data.details[0].closest_campus_id;

      Mixpanel.alias(urlToken);
      Mixpanel.people.set({
        uid: res.data.details[0].url_token,
        // username: response.name,
        $first_name: data.first_name,
        $email: data.email_address,
        modality: modality,
        campusId: closestCampusId,
        campus: closestCampus,
      });

      await localStorage.setItem("token", urlToken);
      await localStorage.setItem("modality", modality);
      await localStorage.setItem("campusId", closestCampusId);
      await localStorage.setItem("campus", closestCampus);

      Mixpanel.track("Registration | Success");
      setIsRegistering(false);
      await setSuccess(true);

      if (
        process.env.REACT_APP_WEBINARS_OPEN &&
        process.env.REACT_APP_WEBINARS_OPEN === "true"
      ) {
        history.push("/home");
      }
    } catch (error) {
      setRegistrationErrors("That didn't work - please try again");
      setIsRegistering(false);
      window.scroll(0, 0);
      Mixpanel.track("Registration | Error");
    }
  };

  const fetchCampuses = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_API_URL + "/campuses/");
      return setCampuses(_.sortBy(res.data, "title"));
    } catch (error) {
      setRegistrationErrors("That didn't work - please try again");
      setIsRegistering(false);
      window.scroll(0, 0);
      Mixpanel.track("Registration | Error");
      console.log(error);
    }
  };
  const fetchModalities = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/modalities-registration/"
      );
      return setModalities(_.sortBy(res.data, "display_label"));
    } catch (error) {
      setRegistrationErrors("That didn't work - please try again");
      setIsRegistering(false);
      window.scroll(0, 0);
      Mixpanel.track("Registration | Error");
      console.log(error);
    }
  };

  const studentTypeChoices = [
    { value: "School", name: "School Leaver" },
    { value: "Domestic", name: "Domestic" },
    { value: "International", name: "International" },
  ];

  useEffect(() => {
    Mixpanel.track("View | Register");
    fetchCampuses();
    fetchModalities();
  }, []);

  return (
    <div
      className="fpx-auto py-auto w-full h-full min-h-screen bg-earth bg-cover bg-center"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)), url(${process.env.REACT_APP_META_IMAGE})`,
      }}
    >
      {success ? (
        <div className="h-screen w-full flex items-center justify-center flex-col px-8">
          <p className="text-white text-2xl mb-4 font-semibold">
            Registration Successful
          </p>
          <p className="text-white text-xl">
            We'll let you know when we have webinars ready for you to register
            for.
          </p>
          <a
            role="button"
            href="https://www.endeavour.edu.au"
            rel="noopener noreferer"
            className="inline-block py-4 px-8 leading-none text-white bg-red-500 hover:bg-red-600 rounded shadow mt-4 font-semibold"
          >
            Visit Endeavour website
          </a>
        </div>
      ) : (
        <section className="m-auto py-8 px-4 w-full md:w-1/2 h-auto">
          <form
            onSubmit={handleSubmit(registerUser)}
            className="mb-4 bg-white p-8 rounded"
            id={
              process.env.REACT_APP_IS_VOD === "true"
                ? "vod-register-form"
                : "fow-register-form"
            }
          >
            <div className="mb-4 text-center">
              <h1 className="">Register</h1>
              {registrationErrors ? (
                <div
                  className="flex items-center bg-joy text-white text-sm font-semibold px-4 py-3 w-full mb-8 mt-8 rounded"
                  role="alert"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="https://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                  </svg>
                  <p>{registrationErrors}</p>
                </div>
              ) : null}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm mb-2">
                First Name
              </label>
              <input
                name="first_name"
                ref={register({ required: true })}
                className="text-box"
                placeholder="Your"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm mb-2">
                Last Name
              </label>
              <input
                name="last_name"
                ref={register({ required: true })}
                className="text-box"
                placeholder="Name"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm mb-2">Email</label>
              <input
                name="email_address"
                type="email"
                ref={register({ required: true })}
                className="text-box"
                placeholder="youremail@email.com"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm mb-2">
                Phone Number
              </label>
              <input
                name="phone_number"
                ref={register({ required: true })}
                className="text-box"
                placeholder="0412345678"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm mb-2">
                Post Code
              </label>
              <input
                name="post_code"
                ref={register({ required: true, minLength: 1 })}
                className="text-box"
                placeholder="2000"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm mb-2">
                Where is your closest campus?
              </label>
              <select
                name="closest_campus"
                ref={register({ required: true, minLength: 1 })}
                className="text-box"
                label="Select campus"
              >
                {" "}
                <option selected disabled value="">
                  Please select...
                </option>
                {campuses.length > 0 &&
                  campuses.map((campus, index) => (
                    <option value={campus.id} key={campus.title}>
                      {campus.title}
                    </option>
                  ))}
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm mb-2">
                What describes you best?
              </label>
              <select
                name="student_type"
                ref={register({ required: true })}
                className="text-box"
              >
                <option selected disabled value="">
                  Please select...
                </option>
                {studentTypeChoices.map((stream, index) => (
                  <option value={stream.value} key={index}>
                    {stream.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm mb-2">
                What are you looking to study?
              </label>
              <select
                name="stream"
                ref={register({ required: true })}
                className="text-box"
              >
                <option selected disabled value="">
                  Please select...
                </option>
                {modalities.length > 0
                  ? modalities.map((modality, index) => (
                      <option value={modality.slug} key={index}>
                        {modality.display_label}
                      </option>
                    ))
                  : null}
              </select>
            </div>

            <div className="mb-5">
              <button
                type="submit"
                className="btn-joy inline-block w-full mt-4 font-semibold text-base"
                id={
                  process.env.REACT_APP_IS_VOD === "true"
                    ? "vod-register-form-submit-button"
                    : "fow-register-form-submit-button"
                }
              >
                {isRegistering ? (
                  <ClipLoader size={24} color={"#fff"} />
                ) : (
                  "Register"
                )}
              </button>
            </div>
          </form>
        </section>
      )}
    </div>
  );
};

export default Register;
