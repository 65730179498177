import React from "react";
import Carousel from "react-multi-carousel";
import { WebinarCard } from "../";
import { useEffect, useState } from "react";
import moment from "moment";

const WebinarsCarousel = ({
  webinars,
  startDate,
  endDate,
  registrantWebinars,
}) => {
  const [inPast, setInPast] = useState(false);
  const [showCarousel, setShowCarousel] = useState(true);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1280 },
      items: 3,
    },
    laptop: {
      breakpoint: { max: 1280, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    let formattedEndDate = moment(endDate);

    const now = moment().local();

    const preciseEndDateDiff = now.preciseDiff(formattedEndDate, true);

    if (preciseEndDateDiff.firstDateWasLater) {
      setInPast(true);
      setShowCarousel(false);
      console.log("in past");
    }
  }, [endDate]);

  return (
    <>
      {showCarousel ? (
        <div className>
          {inPast && showCarousel ? (
            <button
              className="btn m-4"
              onClick={() => setShowCarousel(!showCarousel)}
            >
              Hide Past Events
            </button>
          ) : null}
          <Carousel
            responsive={responsive}
            infinite={false}
            centerMode={false}
            keyBoardControl={false}
          >
            {webinars.map((webinar, index) =>
              webinar.start_time && webinar.end_time && webinar.webinar_key ? (
                <WebinarCard
                  webinar={webinar}
                  key={webinar.id}
                  registered={registrantWebinars.includes(webinar.id)}
                />
              ) : null
            )}
          </Carousel>
        </div>
      ) : (
        <button
          className="btn m-4"
          onClick={() => setShowCarousel(!showCarousel)}
        >
          Show Past Events
        </button>
      )}
    </>
  );
};

export default WebinarsCarousel;
