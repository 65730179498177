import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { PageHeader, Loading, Card } from "../../components";
import { getRegistrant } from "../../api";

import { Mixpanel } from "../../utils/Mixpanel";

const MySessions = () => {
  const [loading, setLoading] = useState(true);

  const [webinars, setWebinars] = useState([]);

  const loadWebinars = async () => {
    try {
      const userToken = localStorage.getItem("token");
      const loadedWebinars = await getRegistrant(userToken);

      await setWebinars(loadedWebinars);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadWebinars();
    Mixpanel.track("View | My Sessions | Existing");
  }, []);

  return (
    <div>
      <>
        <PageHeader
          image={
            "https://virtualopenday.endeavour.edu.au/media/NHCM-My-Sessions-2.jpg"
          }
          title={"My Sessions"}
          description={
            "Here you’ll find all the webinars and sessions you have registered for."
          }
        />
        <section className="w-full p-2 md:px-32 lg:px-64 xl:px-64 text-center border-r">
          <div className="flex justify-center flex-wrap ">
            {loading ? (
              <Loading loading={loading} />
            ) : webinars.length ? (
              webinars.map((webinar, index) => (
                <Card details={webinar} type="event" key={index} />
              ))
            ) : (
              <div className="w-full lg:w-1/2 m-4 p-4 bg-offwhite rounded h-auto flex flex-col justify-center items-center">
                <p className="font-semibold text-lg mb-2">
                  You haven't registered for any webinars yet
                </p>
                <p className="font-small">
                  Browse our list and Register for any webinars you are
                  interested in.
                </p>
                <Link to="/home" role="button" className="btn-joy mt-4 mb-0">
                  Browse Webinars
                </Link>
              </div>
            )}
          </div>
        </section>
      </>
    </div>
  );
};

export default MySessions;
