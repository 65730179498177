import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faCheckCircle,
  faCalendar,
  faCalendarPlus,
} from "@fortawesome/free-regular-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import { faInstagram } from "@fortawesome/free-brands-svg-icons";

import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment-timezone";
import "moment-precise-range-plugin";

import { useDispatch } from "react-redux";
import { setModalDetails, setShowModal } from "../../slices/rewatchModalSlice";

import { Mixpanel } from "../../utils/Mixpanel";

const WebinarCard = ({ webinar, registered }) => {
  const dispatch = useDispatch();

  const [isRegistered, setIsRegistered] = useState(registered);
  const [isRegistering, setIsRegistering] = useState(false);
  const [dateTimes, setDateTimes] = useState({});
  const [eventPast, setEventPast] = useState(false);

  const [presenters, setPresenters] = useState();

  const parsePresenters = () => {
    if (!webinar.presenter.length > 0) {
      return;
    }
    const presentersArr = webinar.presenter.reduce(
      (thesePresenters, presenter) => {
        return thesePresenters.concat(presenter.name);
      },
      []
    );

    setPresenters(presentersArr.toString().replace(",", ", "));
  };

  const registerForWebinar = async () => {
    try {
      setIsRegistering(true);

      const res = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/register-webinar/`,
        data: {
          url_token: localStorage.getItem("token"),
          webinar_key: webinar.webinar_key,
        },
      });

      if (res.status !== 201) {
        throw new Error(res.data);
      }

      setIsRegistered(true);
      Mixpanel.track("Webinar | Register | Success");
    } catch (error) {
      setIsRegistering(false);
      Mixpanel.track("Webinar | Register | Error");
      window.alert("Something went wrong - please try again");
    }
  };

  const toggleModal = async () => {
    Mixpanel.track("Video | Webinar Rewatch");
    dispatch(setModalDetails(webinar));
    dispatch(setShowModal(true));
  };

  const processDates = () => {
    const startDateTime = moment(webinar.start_time).local();
    const endDateTime = moment(webinar.end_time).local();

    const now = moment().local();

    const preciseEndDateDiff = now.preciseDiff(endDateTime, true);
    if (preciseEndDateDiff.firstDateWasLater) {
      setEventPast(true);
    }

    console.log(endDateTime.local());

    setDateTimes({
      startDate: startDateTime.format("ddd MMM D"),
      startTime: startDateTime.format("h:mmA"),
      endTime:
        endDateTime.format("h:mmA") +
        " " +
        moment().tz(moment.tz.guess()).format("z"),
    });
  };

  useEffect(() => {
    processDates();
    parsePresenters();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={
        "h-auto m-4 shadow-md hover:shadow-lg inline-block bg-cover rounded bg-opacity-75 "
      }
    >
      <div
        className="h-48 mb-4 rounded-tr rounded-tl bg-center bg-cover"
        style={
          webinar.feature_image
            ? {
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.3)), url(https://virtualopenday.endeavour.edu.au${webinar.feature_image})`,
              }
            : {
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(
                  ${process.env.REACT_APP_META_IMAGE})`,
              }
        }
      >
        <p className="text-sm px-4 py-4 text-white font-semibold">
          <FontAwesomeIcon icon={faCalendar} /> {dateTimes.startDate}
          <br />
          <FontAwesomeIcon icon={faClock} />
          &nbsp;
          {dateTimes.startTime} - {dateTimes.endTime}
        </p>
        {presenters ? (
          <p className="text-sm px-4 text-white ">Presented by {presenters}</p>
        ) : null}
      </div>

      <div className="h-16 px-4">
        <p className="text-xs uppercase text-wisdom">
          {
            //TODO: This is just temporary untils backend lets you select the "webinar type" - yes I know these aren't webinars anymore but ya know
            webinar.webinar_key.includes("INSTAGRAM")
              ? "Instagram"
              : webinar.webinar_key.includes("https://")
              ? "In Person "
              : "Webinar"
          }
        </p>

        <h3 className="font-semibold">{webinar.title}</h3>
      </div>
      <div className="h-64 px-4 pt-1 pb-4 overflow-y-hidden">
        <p
          className="text-sm whitespace-pre-wrap"
          dangerouslySetInnerHTML={{ __html: webinar.snippet_content }}
        ></p>
      </div>

      <div className="mb-4 px-4">
        {webinar.webinar_key.includes("INSTAGRAM") ? (
          <a
            href="https://www.instagram.com/endeavourcollege"
            rel="noopener noreferrer nofollow"
            target="_blank"
            role="button"
            className="btn-joy mt-4 mb-0 inline-block"
          >
            <FontAwesomeIcon icon={faInstagram} /> Follow us
          </a>
        ) : webinar.webinar_key.includes("https://") ? (
          <a
            href={webinar.webinar_key}
            rel="noopener noreferrer nofollow"
            target="_blank"
            role="button"
            className="btn-joy mt-4 mb-0 inline-block"
          >
            <FontAwesomeIcon icon={faExternalLinkAlt} /> Learn More
          </a>
        ) : eventPast ? (
          webinar.webinar_rewatch_embed ? (
            <button onClick={() => toggleModal()} className="btn-joy mt-4 mb-0">
              Rewatch Webinar
            </button>
          ) : (
            <button
              className="btn-registered mt-4 mb-0 bg-earth"
              disabled={true}
            >
              Event Finished
            </button>
          )
        ) : isRegistered ? (
          <button
            className="btn-registered mt-4 mb-0 bg-tumeric"
            disabled={true}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Registered
          </button>
        ) : (
          <button
            onClick={() => registerForWebinar()}
            className="btn-joy mt-4 mb-0"
            disabled={eventPast}
          >
            {isRegistering ? (
              <ClipLoader size={12} color={"#fff"} />
            ) : (
              <>
                <FontAwesomeIcon icon={faCalendarPlus} /> Register
              </>
            )}
          </button>
        )}
      </div>

      {/* {!eventPast && webinar.webinar_rewatch_embed && showModal ? (
        //Change to !eventPast for testing
        <SproutVideoModal
          display={showModal}
          toggleModal={toggleModal}
          details={webinar}
        />
      ) : null} */}
    </div>
  );
};

export default WebinarCard;
