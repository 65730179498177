import React, { useState, useEffect } from "react";
import VideoModal from "../VideoModal/VideoModal";
import moment from "moment-timezone";
import "moment-precise-range-plugin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faCalendar } from "@fortawesome/free-regular-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";

import { Mixpanel } from "../../utils/Mixpanel";

moment.tz.setDefault("UTC");

const Card = ({ details, type, index }) => {
  const [showModal, setShowModal] = useState(false);
  const [dateDiff, setDateDiff] = useState();
  const [eventPast, setEventPast] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const [eventDateTime, setEventDateTime] = useState({});

  const parseDateTime = async () => {
    if (!details.start_time) {
      return;
    }

    const startDateTime = moment(details.start_time).local();
    const endDateTime = moment(details.end_time).local();

    setEventDateTime({
      startDate: startDateTime.format("ddd MMM Do YYYY"),
      startTime: startDateTime.format("HH:mm"),
      endTime: endDateTime.format("HH:mm"),
    });

    //countdown timer
    let x = setInterval(async () => {
      const now = moment().local();
      await setDateDiff(now.preciseDiff(startDateTime));
      const preciseDateDiff = now.preciseDiff(startDateTime, true);

      const dateDiffObj = now.preciseDiff(startDateTime, true);

      if (
        dateDiffObj.minutes <= 30 &&
        dateDiffObj.hours === 0 &&
        dateDiffObj.days === 0
      ) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }

      if (preciseDateDiff.firstDateWasLater) {
        clearInterval(x);
        setDateDiff();

        setIsOpen(true);
      }

      //opacity added if event in the past
      const preciseEndDateDiff = now.preciseDiff(endDateTime, true);
      if (preciseEndDateDiff.firstDateWasLater) {
        setEventPast(true);
        setIsOpen(false);
      }

      const preciseStartDateDiff = now.preciseDiff(startDateTime, true);
      if (preciseStartDateDiff.firstDateWasLater) {
        setIsOpen(true);
      }
    }, 1000);
  };

  useEffect(() => {
    if (type === "event") {
      parseDateTime();
    }
    // eslint-disable-next-line
  }, [type]);

  const toggleModal = () => {
    Mixpanel.track("Video | View");
    setShowModal(!showModal);
    return showModal;
  };

  return (
    <>
      <div
        className="content-card flex flex-wrap items-center w-full lg:w-3/4 pb-4  h-full lg:mb-16 mb-16 rounded shadow-md hover:shadow-lg"
        key={index}
        data-contenttype={details.content_type}
        style={{ opacity: eventPast ? "0.3" : "1.0" }}
      >
        <div className="w-full h-full">
          <img
            className="rounded-tr rounded-tl object-cover h-72 w-full"
            src={
              details.feature_image
                ? `https://virtualopenday.endeavour.edu.au${details.feature_image}`
                : "https://www.endeavour.edu.au/media/images/Young-woman-working-at-home-1035789156_6720x448.original.jpg"
            }
            alt={details.title}
          />
        </div>

        <div className="w-full px-2">
          <div className="px-6 text-left my-2">
            <p className="text-xs uppercase text-wisdom">
              {details.content_type}
            </p>
            <h3 className="text-xl my-2 font-normal">{details.title}</h3>
            {type === "event" ? (
              <h5 className="text-sm my-3 font-normal">
                <FontAwesomeIcon icon={faCalendar} /> {eventDateTime.startDate}
                <br />
                <FontAwesomeIcon icon={faClock} /> {eventDateTime.startTime} -{" "}
                {eventDateTime.endTime}
                <p className="mt-1">
                  {dateDiff && !isOpen ? `Starts in ${dateDiff}` : null}
                </p>
              </h5>
            ) : null}
            <p
              className="text-sm font-normal w-full h-full text-left  whitespace-pre-wrap"
              dangerouslySetInnerHTML={{
                __html: details.snippet_content,
              }}
            ></p>
          </div>
          <div className="w-full px-4 mt-8 mb-4">
            {type === "content" ? (
              details.content_type === "video" ? (
                <button
                  onClick={() => toggleModal()}
                  rel="noopener noreferrer"
                  className="btn-joy mt-4 mb-0"
                >
                  <FontAwesomeIcon icon={faYoutube} /> Watch Video
                </button>
              ) : (
                <a
                  role="button"
                  disabled={details.read_more_link ? true : false}
                  href={details.read_more_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-joy mt-4 mb-0"
                  onClick={() => Mixpanel.track("Article | View")}
                >
                  <FontAwesomeIcon icon={faBook} /> Read More
                </a>
              )
            ) : details.is_open === true || isOpen ? (
              <a
                role="button"
                href={details.join_link}
                target="_blank"
                rel="noopener noreferrer"
                className="btn-joy mt-4 mb-0"
              >
                Join Webinar
              </a>
            ) : (
              <button
                disabled={true}
                className="btn-joy mt-4 mb-0"
                style={{
                  cursor: eventPast || !isOpen ? "default" : "pointer",
                }}
              >
                {eventPast ? "Event Finished" : `Join Webinar Soon`}
              </button>
            )}
          </div>
        </div>
      </div>
      <VideoModal
        display={showModal}
        toggleModal={toggleModal}
        details={details}
      />
    </>
  );
};

export default Card;
